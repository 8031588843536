<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">Retterstatus</h5>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="
              trainer.filter(
                (data) =>
                  !search ||
                  data.trainerName.toLowerCase().includes(search.toLowerCase())
              )
            "
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Trainer gefunden werden."
            v-loading="loading"
          >
            <el-table-column prop="trainerName" label="Name"> </el-table-column>
            <el-table-column
              prop="trainerLetztePruefung"
              label="Letzte Prüfung"
            >
            </el-table-column>
            <el-table-column prop="status" label="Status" width="150">
              <template slot-scope="scope">
                  <div v-if="scope.row.status == 1" class="text-left">
                    <i
                      class="bi bi-check-circle-fill"
                      style="font-size: 1.2rem; color: green"
                    ></i>
                  </div>
                  <div v-if="scope.row.status == 2" class="text-left">
                    <i
                      class="bi bi-exclamation-triangle-fill"
                      style="font-size: 1.2rem; color: orange"
                    ></i>
                  </div>
                  <div v-if="scope.row.status == 3" class="text-left">
                    <i
                      class="bi bi-exclamation-triangle-fill"
                      style="font-size: 1.2rem; color: red"
                    ></i>
                  </div>
                  <div v-if="scope.row.status == 4" class="text-left">
                    <i
                      class="bi bi-check-circle-fill"
                      style="font-size: 1.2rem; color: blue"
                    ></i>
                  </div>
              </template>
            </el-table-column>
            <el-table-column prop="trainerPruefer" label="Prüfer">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label=""
            >
              <template slot="header" slot-scope="scope"> <!-- eslint-disable-line vue/no-unused-vars -->
                <el-input
                  v-model="search"
                  size="mini"
                  placeholder="Trainer suchen..."
                  ><i slot="prefix" class="bi bi-search"></i
                ></el-input>
              </template>
              <template slot-scope="props" v-if="role > 1">
                <el-tooltip content="Neue Prüfung" placement="top">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="openAddCheckDialog(props.$index, props.row)"
                  >
                    <i class="fa fa-edit"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.addCheck" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">
        Prüfung von {{ selectedTrainer.trainerName }}
      </h5>
      <div class="row">
        <label class="col-md-4 col-form-label">Prüfungsdatum</label>
        <div class="col-md-8">
          <el-date-picker
            v-model="newCheckDate"
            type="date"
            placeholder="Datum wählen"
            :picker-options="pickerOptions1"
            format="dd.MM.yyyy"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-md-4 col-form-label">Prüfer</label>
        <div class="col-md-8">
          <el-select
            class="select"
            placeholder="Prüfer wählen"
            filterable
            v-model="newCheckPruefer"
          >
            <el-option
              v-for="option in HT"
              class="select"
              :value="option.HTname"
              :label="option.HTname"
              :key="option.HTname"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row mt-3" v-if="location == 'DA'">
        <label class="col-md-4 col-form-label"></label>
        <div class="col-md-8">
          <fg-input>
            <p-checkbox v-model="newCheckIsRetterLight"
              >Retter Light <small class="text-muted">(ohne Aufstieg am Seil) </small></p-checkbox
            >
          </fg-input>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="modals.addCheck = false"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="addCheck">Speichern</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { db, fc } from "../../../../firebaseDb";
import { Table, TableColumn, Tooltip } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import { Modal } from "src/components/UIComponents";
import { DatePicker, TimeSelect, Select, Option, Input, Loading } from "element-ui";
import store from "../../../../store";
import { httpsCallable } from "firebase/functions";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(Loading);

export default {
  components: {
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
  },

  data() {
    return {
      trainer: [],
      HT: [],
      search: "",
      lastSync: null,
      location: store.getters.userlocation,
      role: store.getters.userrole,
      loading: true,
      modals: {
        addCheck: false,
      },
      selectedTrainer: "",
      newCheckDate: null,
      newCheckPruefer: null,
      newCheckIsRetterLight: false,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Heute",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
    };
  },
  created() {
    this.loadData();
    store.watch(
      (state) => state.userlocation,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    loadData() {
      // get list of HTs (Puefer)
      const trainerRef = db.collection("trainer");
      trainerRef
        .where("isHT", "==", true)
        .where('locationID', 'array-contains', store.getters.userlocation)
        .orderBy("Name")
        .onSnapshot((snapshotChange) => {
          this.HT = [];
          snapshotChange.forEach((doc) => {
            this.HT.push({
              HTname: doc.data().Name,
            });
          });
        });
      // get list of trainers
      trainerRef
        .where("isRetter", "==", true)
        .where('locationID', 'array-contains', store.getters.userlocation)
        .orderBy("Name")
        .onSnapshot((snapshotChange) => {
          this.trainer = [];
          snapshotChange.forEach((doc) => {
            var status;
            
            let checkDate = ''
            if(doc.data().RetterpruefungDate) {
              checkDate = moment(new Date(doc.data().RetterpruefungDate.seconds * 1000)).format("DD.MM.yyyy").toString()

              if (doc.data().isRetterLight == true && (moment() - doc.data().RetterpruefungDate.seconds * 1000 < 5184000000)) { 
                status = 4; // Retter Light blau
              }
              else if (
                moment() - doc.data().RetterpruefungDate.seconds * 1000 < 4060800000
              ) {
                status = 1; // Retter < 46 Tage (gruen)
              } else if (
                moment() - doc.data().RetterpruefungDate.seconds * 1000 > 4060800000 && moment() - doc.data().RetterpruefungDate.seconds * 1000 < 5184000000
              ) {
                status = 2; // Retter > 46 Tage und < 60 Tage (gelb)
              } 
              else if (
                moment() - doc.data().RetterpruefungDate.seconds * 1000 >= 5184000000
              ) {
                status = 3; // Retter > 60 Tage (rot)
              }
            }
            this.trainer.push({
              key: doc.id,
              trainerName: doc.data().Name,
              trainerID: doc.data().ID,
              trainerLetztePruefung: checkDate,
              trainerPruefer: doc.data().RetterpruefungPruefer,
              status: status
            });
          });
          this.loading = false
        });
    },
    addCheck() {
      if (this.newCheckDate && this.newCheckPruefer) {
        /*if (this.selectedTrainer.trainerLetztePruefung == '' || (this.selectedTrainer.status == 4 && this.newCheckIsRetterLight == false)) {
          // very first check for this trainer. Send notification to PL and office
          var sendNotification = httpsCallable(fc, "trainer-RetterNotification");
          sendNotification({
            TrainerName: this.selectedTrainer.trainerName,
            RetterpruefungPruefer: this.newCheckPruefer,
            RetterpruefungDate: this.newCheckDate,
            IsRetterLight: this.newCheckIsRetterLight,
            loc: store.getters.userlocation
          })
        }*/
        const trainerRef = db.collection("trainer");
        trainerRef
          .doc(this.selectedTrainer.key)
          .update({
            RetterpruefungPruefer: this.newCheckPruefer,
            RetterpruefungDate: this.newCheckDate,
            isRetterLight: this.newCheckIsRetterLight,
          })
          .then(() => {
            this.selectedTrainer = "";
            this.modals.addCheck = false;
            this.newCheckPruefer = null;
            this.newCheckIsRetterLight = false;
            this.newCheckDate = null;
          })
          .catch((error) => {
            console.log("Fehler beim Speichern der Prüfung: " + error);
          });
      }
    },
    openAddCheckDialog(index, row) {
      this.selectedTrainer = row;
      this.modals.addCheck = true;
    },
  },
  computed: {},
};
</script>
<style>
</style>
