<template>
  <ValidationObserver ref="form">
    <form class="form-horizontal" @submit.prevent="validate">
      <div>
        <div class="col-sm-12">
          <h5 class="info-text">Entnahme buchen & Kasse abrechnen</h5>
        </div>
        <ol style="line-height: 230%">
          <li>
            Im Menü <i>Kassenabschluss</i> wählen.
          </li>
          <li>
            Gezählten Bargeldbestand mit Betrag <span class="text-danger mx-3 text-large"
              ><b>{{ sumAll }} €</b></span
            >eingeben und <i>Kassenabschluss vergleichen</i> wählen (ggf. Differenz prüfen).
          </li>
          <li v-if="banktransfer != '0,00'">
            Frage nach Bankentnahme mit <i>Ja</i> bestätigen.
          </li>
          <li v-if="banktransfer != '0,00'">
            Entnahme mit
            <span class="text-danger mx-3 text-large"
              ><b>{{ banktransfer }} €</b></span
            >
            und dem Text <i>Banktransfer</i> im Kassenprogramm buchen.
          </li>
          <li v-if="banktransfer != '0,00'">
            Bankeinzahlung (Entnahme) in der im vorherigen Schritt festgelegten
            Stückelung aus der Kasse entnehmen.
          </li>
          <li>
            Kassenabschluss drucken & abschließen
          </li>
        </ol>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store";
import Button from "../../../../UIComponents/Button.vue";

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(TimePicker);

export default {
  components: {
    Button,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      banktransfer: 0,
      kassenbestand: 0,
      sumAll: 0,
    };
  },
  created() {},
  mounted() {
    this.$watch("$parent.active", (newVal, oldVal) => {
      if (newVal === true) {
        this.updateBanktransfer();
      }
    });
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res);
        return res;
      });
    },
    updateBanktransfer() {
      this.banktransfer = store.getters.banktransfer.data.SumBank
        .toFixed(2)
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      this.kassenbestand = (
        store.getters.cash.data.SumAll - store.getters.banktransfer.data.SumBank
      )
        .toFixed(2)
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      this.sumAll = (
        store.getters.cash.data.SumAll
      )
        .toFixed(2)
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
}
.text-large {
  font-size: 150%;
}
</style>
