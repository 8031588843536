<template>
  <div class="row">
    <div class="col-sm-6">
    <div class="card"
    v-loading="loading"
    element-loading-text="Wird geladen..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <p-button type="default" link style="float: left;" @click="navigateBack()"><i class="bi bi-arrow-left" style="font-size: 16px;"></i></p-button><h5 class="card-title">{{ psaItem }} {{ psaSerialNumber }} <small class="text-muted">({{psaLocation}})</small></h5>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 pt-3">
          <div class="row">
            <div class="col-sm-3">
              <h6>Hersteller</h6>
            </div>
            <div class="col-sm-3">
              <p>{{psaManufacturer}}</p>
            </div>
            <div class="col-sm-3">
              <h6>Kategorie</h6>
            </div>
            <div class="col-sm-3">
              <p>{{psaType}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <h6>Seriennummer</h6>
            </div>
            <div class="col-sm-3">
              <p>{{psaSerialNumber}}</p>
            </div>
            <div class="col-sm-3">
              <h6>Status</h6>
            </div>
            <div class="col-sm-3">
              <el-tag
                  v-if="psaStatus === 'OK'"
                  type="success"
                  size="small"
                  disable-transitions>Einsatzbereit</el-tag>
                <el-tag
                  v-if="psaStatus === 'maintenance'"
                  type="warning"
                  size="small"
                  disable-transitions>Prüfung fällig</el-tag>
                <el-tag
                  v-if="psaStatus === 'NOK'"
                  type="danger"
                  size="small"
                  disable-transitions>Ausgesondert</el-tag>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <h6>Herstellung</h6>
            </div>
            <div class="col-sm-3">
              <p>{{psaProdDate}}</p>
            </div>
            <div class="col-sm-3">
              <h6>Inbetriebnahme</h6>
            </div>
            <div class="col-sm-3">
              <p>{{psaFirstUse}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <h6>Letzte Prüfung</h6>
            </div>
            <div class="col-sm-3">
              <p>{{psaLastMaintenance}}</p>
            </div>
            <div class="col-sm-3">
              <h6>Nächste Prüfung</h6>
            </div>
            <div class="col-sm-3">
              <p>{{psaNextMaintenance}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <h6>Ablegereif</h6>
            </div>
            <div class="col-sm-3">
              <p v-if="psaEOLReached == false">{{psaEOL}}</p>
              <p v-if="psaEOLReached == true" style="color: red !important;"><b>{{psaEOL}}</b></p>
            </div>
            <div class="col-sm-3">
            </div>
            <div class="col-sm-3">
            </div>
          </div>
          <div class="row pt-3 mt-3 ml-1 mr-2 align-items-center ">
            <p-button type="default" @click="navigateToSearch()" wide ><i class="bi bi-search"></i> Suche</p-button>
            <p-button type="default" @click="showGAL()" :disabled="psaGalLink == ''"><i class="bi bi-file-text"></i> GAL anzeigen</p-button>
            <p-button type="default" @click="showIAPA()"><i class="bi bi-exclamation-diamond"></i> Warnungen IAPA</p-button>
            <p-button type="default" @click="showERCA()"><i class="bi bi-exclamation-diamond"></i> Warnunegn ERCA</p-button>
          </div>
          <div class="row ml-1 mr-4 align-items-center">
            <p-button type="success" @click="newMaintenance()" block class="mt-0"><i class="bi bi-wrench"></i> Neue Überprüfung</p-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-3">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h6 class="card-title">Set-Komponenten</h6>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <div class="row ml-1 mr-2">
            <el-table :data="psaComponents" empty-text="Keine Komponenten gefunden">
              <el-table-column
                prop="componentId"
                label="Serien-Nr."
                width="200">
              </el-table-column>
              <el-table-column
                prop="componentName"
                label="Bezeichnung">
              </el-table-column>
              <el-table-column
                label=""
                align="right"
                width="120">
                <template slot-scope="scope">
                  <p-button type="default" size="sm" @click="editComponent(scope.row.componentId, scope.row.componentName, scope.row.key)" class="mr-1" title="Komponente bearbeiten"><i class="bi bi-pencil"></i></p-button>
                  <p-button type="danger" size="sm" @click="removeComponent(scope.row.key)" title="Komponente löschen"><i class="bi bi-trash"></i></p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="row ml-1 mr-2 mt-3 float-right">
            <p-button type="default" @click="newComponent()" class="mt-0" title="Komponente hinzufügen"><i class="bi bi-plus-circle" style="font-size: 16px;"></i></p-button>
          </div>
        </div>
      </div>
    </div>
    </div>
            <div class="col-sm-6">
          <time-line type="">
            <time-line-item
              :class="index % 2 == 1 ? 'timeline-inverted' : 'timeline'"
              v-for="(maintenance, index) in psaMaintenanceHistory"
              :key="maintenance.key"
              :badgeType="maintenance.maintenanceResult == 'Ausgesondert' ? 'danger' : 'success'"
              badgeIcon="timeline-icon bi bi-wrench"
            >
            <badge slot="header" type="danger" v-if="maintenance.maintenanceResult == 'Ausgesondert'">{{ maintenance.maintenanceDate }}</badge>
            <badge slot="header" type="success" v-else><b>{{ maintenance.maintenanceDate }}</b></badge>
            <p slot="content" style="white-space: pre-line;" class="pt-3">
              <b>Ergebnis der Prüfung:</b><br> {{ maintenance.maintenanceResult}}</p>
            <p slot="content" style="white-space: pre-line;" class="pb-2">
              <b>Anmerkungen:</b><br>{{ maintenance.maintenanceNote }}</p>
            <small slot="footer" class="text-muted"><i class="bi bi-person-fill"></i> {{ maintenance.maintenanceTrainer }} ({{ maintenance.maintenanceType }})</small>
          </time-line-item>
          <time-line-item
              class="timeline"
              badgeType="default"
              badgeIcon="timeline-icon bi bi-play-fill"
            >
            <p slot="content"><b>Inbetriebnahme:</b><br>{{ psaFirstUse }}</p>
          </time-line-item>
          <time-line-item
              class="timeline-inverted"
              badgeType="default"
              badgeIcon="timeline-icon bi bi-house-gear"
            >
            <p slot="content"><b>Herstellung:</b><br>{{ psaProdDate }}</p>
          </time-line-item>
        </time-line>
        </div>

      <modal :show.sync="showNewMaintenanceModal" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">Überprüfung {{ psaItem }} {{ psaSerialNumber }}</h5>
      <div class="row">
        <div class="col">
          <label class="pt-1">Datum:</label>
          <el-date-picker v-model="newMaintenanceDate" type="date" placeholder="Datum der Überprüfung" :picker-options="pickerOptionsMaintenance" :clearable="false" format="dd.MM.yyyy" disabled></el-date-picker>
        </div>
        <div class="col">
          <label class="pt-1">Mitarbeiter:</label>
          <el-input v-model="newMaintenanceTrainer" prefix-icon="el-icon-user-solid" disabled></el-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="pt-4">Grund der Überprüfung:</label>
          <el-radio-group v-model="newMaintenanceType" block>
            <el-radio-button label="Planmäßige Prüfung" block></el-radio-button>
            <el-radio-button label="Außerplanmäßige Prüfung"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="pt-4">Kommentare / durchgeführte Reparaturen / Beobachtungen:</label>
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 8}" placeholder="Kommentare/durchgeführte Reparaturen/Beobachtungen..." v-model="newMaintenanceNote"></el-input>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <el-checkbox v-model="newMaintenanceGeneralCheck" class="mb-0"><b>Allgemeine Überprüfung ist erfolgt</b></el-checkbox>
          <ul>
            <li>Kennzeichnung vorhanden und lesbar</li>
            <li>GAL vorhanden</li>
            <li>PSA ist innerhalb der Lebensdauerangabe des Herstellers</li>
            <li>Keine Rückrufe zur PSA vorhanden </li>
          </ul>
          <el-checkbox v-model="newMaintenanceFunctionCheck"><b>Sicht- und Funktionsprüfung gemäß Herstellerangaben durchgeführt</b></el-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="pt-4">Ergebnis der Überprüfung:</label>
          <el-radio-group v-model="newMaintenanceResult">
            <el-radio-button label="Einwandfrei & einsatzfähig"></el-radio-button>
            <el-radio-button label="Ausgesondert"></el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="pt-4">Nächste Prüfung:</label>
          <el-date-picker v-model="newMaintenanceNextDate" type="month" placeholder="Nächste Wartung" :picker-options="pickerOptionsNextMaintenance" :clearable="false" format="MMMM yyyy"></el-date-picker>
        </div>
        <div class="col"></div>
      </div>
      
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showNewMaintenanceModal=false">Abbrechen</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="saveMaintenance()" :disabled="newMaintenanceResult == '' || newMaintenanceType == '' || newMaintenanceGeneralCheck != true || newMaintenanceFunctionCheck != true">Speichern</p-button>
        </div>
      </template>
    </modal>

    <modal :show.sync="componentDialogVisible" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0" v-if="componentMode=='new'">Set-Komponente hinzufügen</h5>
      <h5 slot="header" class="title mb-0" v-else>Set-Komponente bearbeiten</h5>
      <div class="row">
        <div class="col">
          <label class="pt-4">Serien-Nr.:</label>
          <el-input v-model="componentEditId" prefix-icon="el-icon-key"></el-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="pt-4">Bezeichnung:</label>
          <el-input v-model="componentEditName" prefix-icon="el-icon-tickets"></el-input>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="componentDialogVisible=false">Abbrechen</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link v-if="componentMode == 'new'" @click="addComponent()" :disabled="componentEditId == '' || componentEditName == ''">Hinzufügen</p-button>
          <p-button type="success" link v-else @click="updateComponent()" :disabled="componentEditId == '' || componentEditName == ''">Speichern</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover, Tag, MessageBox} from "element-ui";
import { TimeLine, TimeLineItem, Badge, Modal } from "src/components/UIComponents";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);
Vue.use(Tag)

export default {
  components: {
    [Input.name]: Input,
    TimeLine,
    TimeLineItem,
    Badge,
    Modal,
    MessageBox,
  },

  data() {
    return {
      psaId: 0,
      psaItem: "",
      psaManufacturer: "",
      psaSerialNumber: "",
      psaStatus: "",
      psaLastMaintenance: "",
      psaNextMaintenance: "",
      psaType: "",
      psaProdDate: "",
      psaFirstUse: "",
      psaLocationId: "",
      psaLocation: "",
      psaEOL: "",
      psaEOLReached: false,
      psaMaintenanceHistory: [],
      psaComponents: [],
      psaGalLink: "",
      psaLinks: [],
      showNewMaintenanceModal: false,
      loading: true,
      newMaintenanceNote: "",
      newMaintenanceDate: moment(),
      newMaintenanceTrainer: store.getters.user.data.displayName,
      newMaintenanceNextDate: moment().add(1, "years"),
      newMaintenanceType: "",
      newMaintenanceResult: "",
      newMaintenanceGeneralCheck: true,
      newMaintenanceFunctionCheck: true,
      componentDialogVisible: false,
      componentMode: "",
      componentEditId: "",
      componentEditName: "",
      componentEditKey: "",
      pickerOptionsNextMaintenance: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          },
          firstDayOfWeek: 1,
          shortcuts: [{
            text: '1 Monat',
            onClick(picker) {
              picker.$emit('pick', moment().add(1, "months"));
            }
          }, {
            text: '3 Monate',
            onClick(picker) {
              picker.$emit('pick', moment().add(3, "months"));
            }
          }, {
            text: '6 Monate',
            onClick(picker) {
              picker.$emit('pick', moment().add(6, "months"));
            }
          }, {
            text: '12 Monate',
            onClick(picker) {
              picker.$emit('pick', moment().add(12, "months"));
            }
          }]
        },
      pickerOptionsMaintenance: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          firstDayOfWeek: 1,
          shortcuts: []
        },
    };
  },
  created() {
    this.psaId = this.$route.params.psaId
    moment.locale("de");
    this.loadPsaItem()
    store.watch(
      (state) => state.userlocation,
      () => {
        this.loadPsaItem();
      }
    );
  },
  methods: {
    navigateToSearch() {
      router.push({ name: "PsaSearch" });
    },
    showGAL() {
      window.open(this.psaGalLink, "_blank");
    },
    showERCA() {
      window.open("https://www.erca.uk/index.php/de/publikationen/sicherheit/53-sicherheits-warnungen", "_blank");
    },
    showIAPA() {
      window.open("https://iapa.cc/protection/sicherheitsmeldungen/", "_blank");
    },
    newMaintenance() {
      this.showNewMaintenanceModal = true;
    },
    navigateBack() {
      router.go(-1);
    },
    loadPsaItem() {
      // get PSA data from firebase
      const psaRef = db.collection("psa");
      psaRef.doc(this.psaId).get().then((doc) => {
        var status = doc.data().Status;
          if (status != 'NOK' && moment().isSameOrAfter(moment(new Date(doc.data().NextMaintenance.seconds * 1000)))) {
            status = "maintenance";
          }
        var location = ""
        if (doc.data().locationID == "DA") {
          location = "Darmstadt"
        } else if (doc.data().locationID == "FRA") {
          location = "Frankfurt"
        } else if (doc.data().locationID == "NB") {
          location = "Neroberg"
        }
        this.psaItem = doc.data().Item
        this.psaManufacturer = doc.data().Manufacturer
        this.psaSerialNumber = doc.data().SerialNumber
        this.psaStatus = status
        this.psaLastMaintenance = moment(new Date(doc.data().LastMaintenance.seconds * 1000)).format("MMM yyyy").toString()
        this.psaNextMaintenance = moment(new Date(doc.data().NextMaintenance.seconds * 1000)).format("MMM yyyy").toString()
        this.psaType = doc.data().Type
        this.psaProdDate = moment(new Date(doc.data().ProdDate.seconds * 1000)).format("MMM yyyy").toString()
        this.psaFirstUse = moment(new Date(doc.data().FirstUse.seconds * 1000)).format("MMM yyyy").toString()
        this.psaLocationId = doc.data().locationID
        this.psaLocation = location
        this.psaGalLink = doc.data().GalLink || ""
        this.psaEOL = moment(new Date(doc.data().ProdDate.seconds * 1000)).add(doc.data().EOL, "years").format("MMM yyyy").toString()
        if(moment().add(6, "months").isSameOrAfter(moment(new Date(doc.data().ProdDate.seconds * 1000)).add(doc.data().EOL, "years"))) {
          this.psaEOLReached = true
        }

        this.psaMaintenanceHistory = []
        db.collection("psa").doc(this.psaId).collection("maintenance").orderBy("Date", "desc").get().then((snapshot) => {
          snapshot.forEach((doc) => {
            this.psaMaintenanceHistory.push({
              key: doc.id,
              maintenanceDate: moment(new Date(doc.data().Date.seconds * 1000)).format("DD. MMMM yyyy").toString(),
              maintenanceType: doc.data().Type,
              maintenanceResult: doc.data().Result,
              maintenanceNote: doc.data().Note || "-",
              maintenanceTrainer: doc.data().Trainer,
            })
          });
        });

        this.psaComponents = []
        db.collection("psa").doc(this.psaId).collection("components").get().then((snapshot) => {
          snapshot.forEach((doc) => {
            this.psaComponents.push({
              key: doc.id,
              componentId: doc.data().ComponentID,
              componentName: doc.data().Name,
            })
          });
        });

        this.loading = false
      });
    },
    newComponent() {
      this.componentEditId = ""
      this.componentEditName = ""
      this.componentMode = "new"
      this.componentDialogVisible = true
    },
    editComponent(componentId, componentName, key) {
      this.componentEditId = componentId
      this.componentEditName = componentName
      this.componentEditKey = key
      this.componentMode = "edit"
      this.componentDialogVisible = true
    },
    addComponent() {
      db.collection("psa").doc(this.psaId).collection("components").add({
        ComponentID: this.componentEditId,
        Name: this.componentEditName,
        Created: new Date(),
      }).then(() => {
        this.componentEditId = ""
        this.componentEditName = ""
        this.componentEditKey = ""
        this.componentDialogVisible = false
        this.loadPsaItem()
      });
    },
    removeComponent(componentId) {
      MessageBox.confirm(
        "Soll die Komponente wirklich gelöscht werden?",
        "Warnung",
        {
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              db.collection("psa").doc(this.psaId).collection("components").doc(componentId).delete().then(() => {
                this.loadPsaItem()
              });
            }
          },
        }
      );
    },
    updateComponent() {
      db.collection("psa").doc(this.psaId).collection("components").doc(this.componentEditKey).update({
        ComponentID: this.componentEditId,
        Name: this.componentEditName,
        LastUpdate: new Date(),
      }).then(() => {
        this.componentEditId = ""
        this.componentEditName = ""
        this.componentEditKey = ""
        this.componentDialogVisible = false
        this.loadPsaItem()
      });
    },
    saveMaintenance() {
      db.collection("psa").doc(this.psaId).collection("maintenance").add({
          Date: new Date(this.newMaintenanceDate),
          Note: this.newMaintenanceNote,
          Trainer: this.newMaintenanceTrainer,
          NextDate: new Date(this.newMaintenanceNextDate),
          Type: this.newMaintenanceType,
          Result: this.newMaintenanceResult,
          Timestamp: new Date(),
        })
        .then(() => {
          db.collection("psa").doc(this.psaId).update({
            LastMaintenance: new Date(this.newMaintenanceDate),
            NextMaintenance: new Date(this.newMaintenanceNextDate),
            Status: this.newMaintenanceResult == "Ausgesondert" ? "NOK" : "OK"
          }).then(() => {
            this.newMaintenanceNote = ""
            this.newMaintenanceDate = moment()
            this.newMaintenanceTrainer = store.getters.user.data.displayName
            this.newMaintenanceNextDate = moment().add(1, "years")
            this.newMaintenanceType = ""
            this.newMaintenanceResult = ""
            this.showNewMaintenanceModal = false
            this.loadPsaItem()
            this.$notify({
              message: "Überprüfung wurde erfolgreich gespeichert!",
              icon: "fa fa-check-circle",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "success",
              showClose: true,
              timeout: 5000,
            });
          }).catch(() => {
            this.$notify({
              message: "Fehler beim Speichern der Überprüfung! Bitte Internetverbindung prüfen.",
              icon: "fa fa-exclamation-circle",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "danger",
              showClose: true,
              timeout: 10000,
            });
          });
        }).catch(() => {
          this.$notify({
            message: "Fehler beim Speichern der Überprüfung! Bitte Internetverbindung prüfen.",
            icon: "fa fa-exclamation-circle",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger",
            showClose: true,
            timeout: 10000,
          });
        });
    }
  },
};
</script>
<style>
.el-tag {
  color: white !important;
  margin-left: 0px !important;
}
.timeline-icon {
  line-height: 2.1;
  width: 25px;
  text-align: center;
  font-size: 22px;
}
.el-descriptions-item__label {
  font-weight: bold;
}
.timeline-heading {
  margin-bottom: 0px !important;
}
.el-radio-button__inner {
  width: 100% !important;
}
.el-radio-group {
  width: 100% !important;
}
.el-radio-button {
  width: 50% !important;
}
</style>
