<template>
  <div>
    <div class="row">
      <p-button type="success" @click="goBack" class="mb-4"><i class="bi bi-arrow-left"></i> Zurück</p-button>
    </div>
    <div class="row">
      <iframe :src=pdfUrl id="frame" width="1300" height="1700"></iframe>
    </div>
  </div>
</template>
<script>

  import { storage } from '../../../../firebaseDb'
  import { ref, getDownloadURL } from "firebase/storage";
  import store from "../../../../store";
  import router from "../../../../main";

  export default {
    data () {
      return {
        filepath: null,
        pdfUrl: null
      }
    },
    created () {
      var requestedFile = this.$route.params.file
      this.loadFile(requestedFile)
      store.watch(
      (state) => state.userlocation,
      () => {
        this.loadFile(requestedFile);
      }
    );
    },
    methods: {
      loadFile(requestedFile) {
        
        if(requestedFile === 'unfallprotokoll') {
          if(store.getters.userlocation == "DA") {
            this.filepath = "/PDF/Unfallprotokoll-da.pdf"
          }
          if(store.getters.userlocation == "NB") {
            this.filepath = "/PDF/Unfallprotokoll-nb.pdf"
          }
          if(store.getters.userlocation == "FRA") {
            this.filepath = "/PDF/Unfallprotokoll-fra.pdf"
          }
        }
        else if(requestedFile === 'kinderprogramme') {
          if(store.getters.userlocation == "DA") {
            this.filepath = "/PDF/Kinderprogramme-Liste-da.pdf"
          }
          if(store.getters.userlocation == "NB") {
            this.filepath = "/PDF/Kinderprogramme-Liste-nb.pdf"
          }
          if(store.getters.userlocation == "FRA") {
            this.filepath = "/PDF/Kinderprogramme-Liste-fra.pdf"
          }
        } else {
          this.filepath = "/PDF/" + requestedFile + ".pdf"
        }
        

        getDownloadURL(ref(storage, this.filepath)).then((url) => {
          this.pdfUrl = url
        })
      },
      goBack() {
        router.go(-1)
      }
    },
    watch: {
      '$route.params.file': function (file) {
        this.loadFile(file)
      }
    }
  }

</script>
<style>

</style>
