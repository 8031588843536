<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">Kontaktliste</h5>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="
              trainer.filter(
                (data) =>
                  !search ||
                  data.trainerName.toLowerCase().includes(search.toLowerCase())
              )
            "
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Trainer gefunden werden."
            v-loading="loading"
          >
            <el-table-column prop="trainerName" label="Name"> </el-table-column>
            <el-table-column prop="trainerTelefon" label="Telefon">
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="left" label="">
              <template slot-scope="props">
                <el-tooltip content="QR-Code anzeigen zum Anrufen" placement="top">
                <el-popover
                  placement="left"
                  title="QR-Code scannen zum Anrufen"
                  width="300"
                  trigger="click"
                  v-if="props.row.trainerTelefon">
                  <div style="text-align:center;"><center><qr-code :text="'tel:' + props.row.trainerTelefon" :size=128></qr-code><small>{{props.row.trainerName}}</small></center></div>
                  <p-button slot="reference" type="default" size="sm" icon><i class="bi bi-qr-code"></i></p-button>
                </el-popover>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="" label="">
              <template slot="header" slot-scope="scope"> <!-- eslint-disable-line vue/no-unused-vars -->
                <el-input
                  v-model="search"
                  size="mini"
                  placeholder="Trainer suchen..."
                  ><i slot="prefix" class="bi bi-search"></i
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
  },

  data() {
    return {
      loading: true,
      trainer: [],
      search: "",
      location: store.getters.userlocation,
    };
  },
  created() {
    // get list of trainers
    this.loadData();
    store.watch(
      (state) => state.userlocation,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    loadData() {
      // get list of trainers
      this.loading = true;
      this.location = store.getters.userlocation
      console.log("location: " + this.location)
      const trainerRef = db.collection("trainer");
      trainerRef.where('locationID', 'array-contains', store.getters.userlocation).orderBy("Name").onSnapshot((snapshotChange) => {
        this.trainer = [];
        snapshotChange.forEach((doc) => {
          this.trainer.push({
            key: doc.id,
            trainerName: doc.data().Name,
            trainerID: doc.data().ID,
            trainerTelefon: doc.data().Telefon,
          });
        });
        this.loading = false
      });
    },
  },
};
</script>
<style>
</style>
