<template>
  <div>
    <div class="col-sm-12">
      <h5 class="info-text">
        Übertrag von Kassenabschluss-Bon, Kartenzahlungen, Contao etc.
      </h5>
    </div>
    <div class="row">
      <div class="col-md-8">
        <ValidationObserver ref="form">
          <form class="form-horizontal" @submit.prevent="validate">
            <div class="row ml-1">
              <div class="col-sm-6 pb-3">
                <label>Kartenzahlungen vom Abschlussbon</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider
                    name="ecschnitt"
                    rules="required"
                    v-slot="{failed}"
                  >
                    <fg-input
                      placeholder="0,00"
                      type="number"
                      v-model="ecschnitt"
                      min="0"
                      :error="failed ? 'Kartenzahlungen müssen angegeben werden.': null"
                    >
                      <template slot="addonRight">€</template>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-6">
                <label>Barbestand (Soll) vom Abschluss-Bon</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider
                    name="berechnetzbon"
                    rules="required"
                    v-slot="{failed}"
                  >
                    <fg-input
                      placeholder="0,00"
                      type="number"
                      v-model="berechnetzbon"
                      min="0"
                      :error="failed ? 'Der Barbestand (Soll) vom Abschluss-Bon muss angegeben werden.': null"
                    >
                      <template slot="addonRight">€</template>
                    </fg-input>
                  </ValidationProvider>
                  <span v-show="berechnetzbon > 0 && berechnetzbon < 200" class="warning">Der Barbestand ist ungewöhnlich gering.</span>
                  <span v-show="berechnetzbon > 800" class="warning">Der Barbestand ist ungewöhnlich hoch.</span>
                </div>
              </div>
            </div>
            <div class="row ml-1">
              <div class="col-sm-6 pb-3">
                <label>Evtl. Ausgaben</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider name="ausgaben" rules="" v-slot="{}">
                    <fg-input
                      placeholder="0,00"
                      type="number"
                      v-model="ausgaben"
                      min="0"
                      class="mb-0"
                    >
                      <template slot="addonRight">€</template>
                    </fg-input>
                    <span class="small pt-0"><i>Beinhaltet nicht die Bankeinzahlung</i></span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-6 pb-3">
                <label>Online bezahlte Buchungen (Contao)</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider
                    name="online"
                    rules="required"
                    v-slot="{failed}"
                  >
                    <fg-input
                      placeholder="0,00"
                      type="number"
                      v-model="online"
                      min="0"
                      :error="failed ? 'Die Summe der online bezahlten Buchungen muss angegeben werden.': null"
                      :disabled="onlineCovered == true || onlinePayedLoading == true || (onlinePayedReceived == true && onlineCount > 0)"
                    >
                      <template slot="addonRight" v-if="onlinePayedLoading"><i class="bi bi-hourglass-split"></i></template>
                      <template slot="addonRight" v-else>€</template>
                    </fg-input>
                  </ValidationProvider>
                  <span v-show="onlinePayedReceived" class="small pt-0"><i>Es wurden {{ onlineCount }} online bezahlte Buchungen gefunden.</i></span>
                  <span v-show="onlineCovered" class="small pt-0"><i>Die online bezahlten Buchungen wurden bereits in einem anderen Kassenabschluss erfasst.</i></span>
                  <span v-show="online > 0 && online < 50" class="warning">Die Summe der online bezahlten Buchungen ist ungewöhnlich gering.</span>
                  <span v-show="online > 5000" class="warning">Die Summe der online bezahlten Buchungen ist ungewöhnlich hoch.</span>
                </div>
              </div>
            </div>
            <div class="row ml-1 mt-2">
              <h6>Gutscheine</h6>
            </div>  
            <div class="row ml-1">
              <div class="col-sm-6 pb-3">
                <label>Anzahl der eingelösten Gutscheine</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider
                    name="gutscheineAnzahl"
                    rules="required"
                    v-slot="{failed}"
                  >
                    <fg-input
                      placeholder="0"
                      type="number"
                      v-model="gutscheineAnzahl"
                      min="0"
                      :error="failed ? 'Die Anzahl der eingelösten Gutscheine muss angegeben werden.': null"
                    >
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-6 pb-3">
                <label>Gesamtbetrag der eingelösten Gutscheine</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider
                    name="gutscheine"
                    rules="required"
                    v-slot="{failed}"
                  >
                    <fg-input
                      placeholder="0,00"
                      type="number"
                      v-model="gutscheine"
                      min="0"
                      :disabled="gutscheineAnzahl < 1"
                      :error="failed ? 'Die Summe der eingelösten Gutscheine muss angegeben werden.': null"
                    >
                      <template slot="addonRight">€</template>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row ml-1 mt-2">
              <h6>Stornos</h6>
            </div>  
            <div class="row ml-1">
              <div class="col-sm-6 pb-3">
                <label>Anzahl der Storno-Belege</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider
                    name="stornosAnzahl"
                    rules="required"
                    v-slot="{failed}"
                  >
                    <fg-input
                      placeholder="0"
                      type="number"
                      v-model="stornosAnzahl"
                      min="0"
                      :error="failed ? 'Die Anzahl der Storno-Belege muss angegeben werden.': null"
                    >
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-6 pb-3">
                <label>Summe der Stornos</label>
                <div class="col-sm-10 pl-0">
                  <ValidationProvider
                    name="stornos"
                    rules="required"
                    v-slot="{failed}"
                  >
                    <fg-input
                      placeholder="0,00"
                      type="number"
                      v-model="stornos"
                      min="0"
                      :disabled="stornosAnzahl < 1"
                      :error="failed ? 'Die Summe der stornierten Beträge muss angegeben werden.': null"
                    >
                      <template slot="addonRight">€</template>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row ml-1 mt-2">
              <h6>Rechnungen</h6>
            </div>            
            <div class="row ml-1">            
              <div class="col-sm-6 pb-3">
                <label>Rechnungen (Schulklassen etc.)</label>
                <div class="col-sm-10 pl-0">
                  <fg-input
                    placeholder="Bestell-Nr. hinzufügen"
                    type="text"
                    v-model="newRechnung"
                    @keyup.enter="addRechnung"
                    @blur="addRechnung"
                  >
                  </fg-input>
                </div>
                <div class="col-sm-12 pl-0">
                  <el-tag
                    :key="tag"
                    v-for="tag in rechnungen"
                    type="primary"
                    :closable="true"
                    :close-transition="false"
                    @close="removeRechnung(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                </div>
              </div>
              <div class="col-sm-6 pb-3">
                <label>Veranstaltungen</label>
                <div class="col-sm-10 pl-0">
                  <fg-input
                    placeholder="Bestell-Nr. hinzufügen"
                    type="text"
                    v-model="newVA"
                    @keyup.enter="addVA"
                    @blur="addVA"
                  >
                  </fg-input>
                </div>
                <div class="col-sm-12 pl-0">
                  <el-tag
                    :key="tag"
                    v-for="tag in veranstaltungen"
                    type="primary"
                    :closable="true"
                    :close-transition="false"
                    @close="removeVA(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div class="col-md-4">
        <collapse>
          <collapse-item title="Wo finde ich die Kartenzahlungen auf dem Abschluss-Bon?" name="1">
            <div>
              <img
                :src="urlImageECSchnitt"
                class="rounded float-right"
                alt="Kartenzahlungen Abschluss-Bon"
              />
            </div>
          </collapse-item>
          <collapse-item
            title='Wo finde ich "Barbestand (Soll)" auf dem Abschluss-Bon?'
            name="2"
          >
            <div>
              <img
                :src="urlImageZBon"
                class="rounded float-right"
                alt="Barbestand Abschluss-Bon"
              />
            </div>
          </collapse-item>
        </collapse>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db, fc } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  Tag,
} from "element-ui";
import moment from "moment-timezone";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store";
import { Collapse, CollapseItem } from "src/components/UIComponents";
import { storage } from "../../../../../firebaseDb";
import { ref, getDownloadURL } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);

export default {
  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
    CollapseItem,
    Collapse,
  },
  data() {
    return {
      ecschnitt: null,
      berechnetzbon: null,
      gutscheine: 0,
      gutscheineAnzahl: 0,
      stornos: 0,
      stornosAnzahl: 0,
      online: null,
      onlineCount: 0,
      ausgaben: 0,
      rechnungen: [],
      newRechnung: "",
      veranstaltungen: [],
      newVA: "",
      urlImageECSchnitt: "",
      urlImageZBon: "",
      onlineCovered: false,
      onlinePayedLoading: false,
      onlinePayedReceived: false,
      location: store.getters.userlocation,
    };
  },
  created() {
    // get list of trainers (HT and Kasse)
    async function getTrainerKasseOrHT() {
      const trainerRef = db.collection("trainer");
      const isKasse = trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).where("isKasse", "==", true).get();
      const isHT = trainerRef.where('locationID', 'array-contains', store.getters.userlocation).where('isToday', '==', true).where('isTodayLocation', '==', store.getters.userlocation).where("isHT", "==", true).get();

      const [KasseQuerySnapshot, HTQuerySnapshot] = await Promise.all([
        isKasse,
        isHT,
      ]);

      const KasseArray = KasseQuerySnapshot.docs;
      const HTArray = HTQuerySnapshot.docs;

      const trainerArray = KasseArray.concat(HTArray);

      return trainerArray;
    }

    getTrainerKasseOrHT().then((result) => {
      this.trainer = [];
      result.forEach((doc) => {
        const index = this.trainer.findIndex((element) => {
          if (element.trainerName == doc.data().Name) {
            return true;
          }
        });
        if (index === -1) {
          this.trainer.push({
            trainerName: doc.data().Name,
          });
        }
      });
    });

    getDownloadURL(ref(storage, "eckassenschnitt.jpg")).then((url) => {
      this.urlImageECSchnitt = url;
    });
    getDownloadURL(ref(storage, "bonkassenabschluss.jpg")).then((url) => {
      this.urlImageZBon = url;
    });

    
  },
  mounted() {
    this.$watch("$parent.active", (newVal, oldVal) => {
      if (newVal === true) {
        // check online bookings are already covered
        var reportName = moment(store.getters.kassedate, "DD.MM.YYYY").format("YYYYMMDD") + '-' + this.location;
        const tagesReportRef = db.collection("tagesreports");
        tagesReportRef
            .doc(reportName)
            .get()
            .then((doc) => {
              if (doc.exists) {
                this.onlineCovered = doc.data().onlineBookingsCovered || false
              }
              if (this.onlineCovered == false) {
                this.getOnlineBookings();
              } else {
                this.online = 0;
                console.log("Online bookings are already covered.")
              }
            })
      }
    });
  },
  methods: {
    getOnlineBookings() {
      console.log("Getting online payed bookings from Contao");
      this.onlinePayedLoading = true;
      var getOnlinePayed = httpsCallable(fc, "contao-getOnlinePayedBookingsToday");
        getOnlinePayed({
          location: this.location
        }).then((result) => {
          console.log('Received data from Contao.')
          this.online = result.data.sum
          this.onlineCount = result.data.count
          this.onlinePayedLoading = false
          this.onlinePayedReceived = true
        });
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        store.commit("SET_ECSCHNITT", this.ecschnitt);
        store.commit("SET_BERECHNETZBON", this.berechnetzbon);
        store.commit("SET_GUTSCHEINE", this.gutscheine);
        store.commit("SET_ONLINEBEZ", this.online);
        store.commit("SET_AUSGABEN", this.ausgaben);
        this.$emit("on-validated", res);
        return res;
      });
    },
    removeRechnung(tag) {
      this.rechnungen.splice(this.rechnungen.indexOf(tag), 1);
    },

    addRechnung() {
      let inputValue = this.newRechnung;
      if (inputValue) {
        this.rechnungen.push(inputValue);
      }
      this.newRechnung = "";
    },
    removeVA(tag) {
      this.veranstaltungen.splice(this.veranstaltungen.indexOf(tag), 1);
    },

    addVA() {
      let inputValue = this.newVA;
      if (inputValue) {
        this.veranstaltungen.push(inputValue);
      }
      this.newVA = "";
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.warning {
  color: orange;
  font-weight: bold;
  font-size: small;
}
</style>
