<template>
  <div>
    <div class="header">
      <h5 class="title">Anmerkungen des Tages</h5>
    </div>
    <div class="pt-1 pb-2">
      <button
        type="submit"
        class="btn btn-success btn-fill btn-wd"
        @click.prevent="openAddAnmerkungDialog"
      >
        Neue Anmerkung
      </button>
    </div>
    <time-line type="simple" class="col-lg-6">
      <time-line-item
        class="timeline-inverted"
        v-for="anmerkung in anmerkungen"
        :key="anmerkung.key"
        :badgeType="anmerkung.type"
        badgeIcon="nc-icon nc-single-copy-04"
      >
        <badge slot="header" type="danger" v-if="anmerkung.important"
          >Wichtig!</badge
        >
        <p slot="content" style="white-space: pre-line;">{{ anmerkung.text }}</p>

        <h6 slot="footer" class="pt-2">
          <i class="fa fa-clock-o"></i>
          {{ anmerkung.timestamp }} Uhr
        </h6>
      </time-line-item>
    </time-line>
    <p class="pt-3 small">
      <i>Es werden nur die Anmerkungen der letzten 7 Tage angezeigt.</i>
    </p>

    <modal
      :show.sync="modals.addAnmerkung"
      headerClasses="justify-content-center"
      modalMaxWidth="800px"
    >
      <h5 slot="header" class="title mb-0">Neue Anmerkung erstellen</h5>
      <fieldset>
        <div class="form-group">
          <label class="col-sm-2 control-label">Anmerkung</label>
          <div class="col-sm-12">
            <textarea
              class="form-control"
              placeholder="Deine Anmerkung..."
              rows="7"
              v-model="newAnmerkung"
            ></textarea>
          </div>
        </div>
      </fieldset>
      <div class="row mt-3 ml-2">
        <div class="col-md-12">
          <fg-input>
            <p-checkbox v-model="newAnmerkungImportant"
              >als wichtig markieren</p-checkbox
            >
          </fg-input>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="modals.addAnmerkung = false; newAnmerkung = ''; newAnmerkungImportant = false"
            >Abbrechen</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="saveAnmerkung"
            >Speichern</p-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem, Badge } from "src/components/UIComponents";
import { db } from "../../../../firebaseDb";
import moment from "moment-timezone";
import { Modal } from "src/components/UIComponents";
import store from "../../../../store";

export default {
  components: {
    TimeLine,
    TimeLineItem,
    Badge,
    Modal,
  },

  data() {
    return {
      anmerkungen: [],
      newAnmerkung: "",
      newAnmerkungImportant: false,
      modals: {
        addAnmerkung: false,
      },
    };
  },
  created() {
    this.loadData();
    store.watch(
      (state) => state.userlocation,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    loadData() {
      moment.locale("de");
      var dateLimit = new Date(new Date().getTime() - 604800000); // 7 days ago
      const anmerkungenRef = db.collection("anmerkungen");
      anmerkungenRef
        .where('locationID', '==', store.getters.userlocation)
        .where("timestamp", ">=", dateLimit)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshotChange) => {
          this.anmerkungen = [];
          snapshotChange.forEach((doc) => {
            var type = null;
            if (doc.data().important) {
              type = "danger";
            } else {
              type = "success";
            }
            this.anmerkungen.push({
              key: doc.id,
              text: doc.data().text,
              important: doc.data().important,
              timestamp: moment(new Date(doc.data().timestamp.seconds * 1000))
                .format("dddd, DD. MMM HH:mm")
                .toString(),
              type: type,
            });
          });
        });
    },
    saveAnmerkung() {
      db.collection("anmerkungen")
        .add({
          text: this.newAnmerkung,
          important: this.newAnmerkungImportant,
          timestamp: new Date(),
          locationID: store.getters.userlocation
        })
        .then(() => {
          this.modals.addAnmerkung = false;
          this.newAnmerkung = "";
          this.newAnmerkungImportant = false;
        })
        .catch(() => {
          console.log("Adding Anmerkung failed");
        });
    },
    openAddAnmerkungDialog() {
      this.modals.addAnmerkung = true;
    },
  },
};
</script>
<style>
textarea.form-control,
.form-group textarea.el-input__inner {
  max-height: 250px !important;
}
</style>
