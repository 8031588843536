<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">Rabattliste</h5>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="
            rabatte.filter(
              (data) =>
                !search ||
                data.rabattGruppe.toLowerCase().includes(search.toLowerCase())
            )
          " header-row-class-name="text-primary" empty-text="Es konnten keine Rabatte gefunden werden."
            v-loading="loading">
            <el-table-column prop="rabattGruppe" label="Gruppe" width="300"></el-table-column>
            <el-table-column prop="rabattBeschreibung" label="Rabatt">
            </el-table-column>
            <el-table-column class-name="action-buttons td-actions" align="right" label="" width="100">
              <template slot-scope="props" >
                <el-tooltip content="Anhang anzeigen" placement="top">
                  <el-popover
                    placement="left"
                    width="500"
                    trigger="click"
                    v-if="props.row.rabattImage"
                  >
                  <img :src=props.row.rabattImage>
                  <p-button slot="reference" type="default" size="sm" icon><i class="fa fa-image"></i></p-button>
                </el-popover>
                  
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
  },

  data() {
    return {
      loading: true,
      rabatte: [],
      search: "",
    };
  },
  created() {
    this.loadData();
    store.watch(
      (state) => state.userlocation,
      () => {
        this.loadData();
      }
    );
  },
  methods: {
    loadData() {
       // get list of Rabatte
      const rabatteRef = db.collection("rabatte");
      rabatteRef.where('locationID', '==', store.getters.userlocation).orderBy("Group").onSnapshot((snapshotChange) => {
        this.rabatte = [];
        snapshotChange.forEach((doc) => {
          this.rabatte.push({
            key: doc.id,
            rabattGruppe: doc.data().Group,
            rabattBeschreibung: doc.data().Discount,
            rabattImage: doc.data().Attachment || null
          });
        });
        this.loading = false
      });
    }
  },
};
</script>
<style>
.el-table .cell {
  word-break: break-word !important;
}
</style>
