<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-7">
            <h5 class="card-title">{{ psaCategory }}</h5>
          </div>
          <div class="col-sm-5 pt-1">
            <div class="row">
              <div class="col-sm-auto">
                <p-button type="default" @click="newPsa()" title="PSA hinzufügen" class="mt-0 mr-0"><i class="bi bi-plus-circle" style="font-size: 16px;"></i></p-button>
              </div>
              <div class="col-sm-auto pl-0" >
                <el-select v-model="psaCategory" placeholder="PSA-Kategorie wählen" @change="updatePsaCategory">
                  <el-option v-for="category in psaCategories" :value="category.psaCatName" :label="category.psaCatName" :key="category.key"></el-option>
                </el-select>
              </div>
              <!--<div class="col-sm-auto pl-0" >
                <SelectLocation v-if="role > 2" @locationChanged="updateLocation" class="pl-0"></SelectLocation>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row mb-4 pb-3 pt-2">
          <div class="col-sm-4">
            <el-statistic title="Einsatzbereit" :value=counterOK group-separator="." :precision="0"></el-statistic>
          </div>
          <div class="col-sm-4">
            <el-statistic title="Prüfung fällig" :value=counterMaintenance group-separator="." :precision="0"></el-statistic>
          </div>
          <div class="col-sm-4">
            <el-statistic title="Ausgesondert" :value=counterNOK group-separator="." :precision="0"></el-statistic>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table 
            ref="psaTable"
            :data="
              psa.filter(
                (data) =>
                  !search ||
                  data.psaType.toLowerCase().includes(search.toLowerCase())
              )
            " header-row-class-name="text-primary" empty-text="Es konnte keine PSA gefunden werden."
              v-loading="loading"
              @row-click="showDetails">
              <el-table-column prop="psaSerialNumber" label="Serien-Nr." width="300"></el-table-column>
              <el-table-column prop="psaManufacturer" label="Hersteller"></el-table-column>
              <el-table-column prop="psaItem" label="Artikel"></el-table-column>
              <el-table-column prop="psaStatus" label="Status"
                :filters="[{ text: 'Einsatzbereit', value: 'OK' }, { text: 'Prüfung fällig', value: 'maintenance' }, { text: 'Ausgesondert', value: 'NOK' }]"
                :filter-method="filterStatus"
                filter-placement="bottom-end"
                align="center">
                <template slot-scope="scope">
                  <el-tag
                    v-if="scope.row.psaStatus === 'OK'"
                    type="success"
                    disable-transitions>Einsatzbereit</el-tag>
                  <el-tag
                    v-if="scope.row.psaStatus === 'maintenance'"
                    type="warning"
                    disable-transitions>Prüfung fällig</el-tag>
                  <el-tag
                    v-if="scope.row.psaStatus === 'NOK'"
                    type="danger"
                    disable-transitions>Ausgesondert</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="psaLastMaintenance" label="Letzte Prüfung"></el-table-column>
              <el-table-column prop="psaNextMaintenance" label="Nächste Prüfung"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showNewPsaModal" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">PSA hinzufügen</h5>
      <div class="row">
        <div class="col">
          <label class="pt-1">Vorlage:</label>
          <el-select v-model="newPsaTemplate" placeholder="Vorlage auswählen" clearable>
            <el-option v-for="template in psaTemplates" :value=template.psaTemplateName :label=template.psaTemplateName :key=template.key></el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="pt-4">Serien-Nr.:</label>
          <el-input v-model="newPsaSerialNumer" prefix-icon="el-icon-key" :disabled="newPsaTemplate == ''"></el-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="pt-4">Herstellung:</label>
          <el-date-picker v-model="newPsaProdDate" type="month" placeholder="Herstellungsdatum" :picker-options="pickerOptions" :clearable="false" format="MMMM yyyy" :disabled="newPsaTemplate == ''"></el-date-picker>
        </div>
        <div class="col">
          <label class="pt-4">Inbetriebnahme:</label>
          <el-date-picker v-model="newPsaFirstUse" type="month" placeholder="Inbetriebnahme" :picker-options="pickerOptions" :clearable="false" format="MMMM yyyy" :disabled="newPsaTemplate == ''"></el-date-picker>
        </div>
      </div>
      

      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="showNewPsaModal=false">Abbrechen</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="addPsa()" :disabled="newPsaTemplate == '' || newPsaSerialNumer == '' || newPsaProdDate == '' || newPsaFirstUse == ''">Hinzufügen</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover, Tag, Select, Option, Statistic } from "element-ui";
import { Modal } from "src/components/UIComponents";
import moment from "moment-timezone";
import router from "../../../../main";
import store from "../../../../store";
import SelectLocation from '/src/components/KET/Views/Components/KETSelectLocation.vue'
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);
Vue.use(Tag)
Vue.use(Statistic)

export default {
  components: {
    [Input.name]: Input,
    SelectLocation,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      loading: true,
      psa: [],
      counterOK: 0,
      counterNOK: 0,
      counterMaintenance: 0,
      search: "",
      showNewPsaModal: false,
      psaTemplates: [],
      newPsaTemplate: "",
      newPsaSerialNumer: "",
      newPsaFirstUse: "",
      newPsaProdDate: "",
      psaCategories: [],
      psaCategory: "",
      role: store.getters.userrole,
      location: store.getters.userlocation,
      pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          firstDayOfWeek: 1,
          shortcuts: []
        },
    };
  },
  created() {
    moment.locale("de");
    this.loadPsaCategories();
    if(localStorage.SelectedPsaCategory) {
      this.psaCategory = localStorage.SelectedPsaCategory;
    }
    this.loadPsa();
    store.watch(
      (state) => state.userlocation,
      () => {
        this.location = store.getters.userlocation;
        this.loadPsa();
      }
    );
  },
  methods: {
    loadPsaCategories() {
      const psaCatRef = db.collection("psaCategories");
      psaCatRef.orderBy("Name").onSnapshot((snapshotChange) => {
        this.psaCategories = [];
        snapshotChange.forEach((doc) => {
          this.psaCategories.push({
            key: doc.id,
            psaCatName: doc.data().Name,
          });
        });
      });
    },
    loadPsa() {
      // get PSA data from firebase
      const psaRef = db.collection("psa");
      psaRef.where('locationID', '==', this.location).where('Type', '==', this.psaCategory).orderBy("SerialNumber").onSnapshot((snapshotChange) => {
        this.psa = [];
        snapshotChange.forEach((doc) => {
          var status = doc.data().Status;
          if (status != 'NOK' && moment().isSameOrAfter(moment(new Date(doc.data().NextMaintenance.seconds * 1000)))) {
            status = "maintenance";
          }
          this.psa.push({
            key: doc.id,
            psaType: doc.data().Type,
            psaManufacturer: doc.data().Manufacturer,
            psaItem: doc.data().Item,
            psaSerialNumber: doc.data().SerialNumber,
            psaProdDate: moment(new Date(doc.data().ProdDate.seconds * 1000)).format("MMM yyyy").toString(),
            psaFirstUse: moment(new Date(doc.data().FirstUse.seconds * 1000)).format("MMM yyyy").toString(),
            psaLastMaintenance: moment(new Date(doc.data().LastMaintenance.seconds * 1000)).format("MMM yyyy").toString(),
            psaNextMaintenance: moment(new Date(doc.data().NextMaintenance.seconds * 1000)).format("MMM yyyy").toString(),
            psaStatus: status,
          });
        });
        this.counterOK = this.psa.filter(x => x.psaStatus == 'OK').length;
        this.counterNOK = this.psa.filter(x => x.psaStatus == 'NOK').length;
        this.counterMaintenance = this.psa.filter(x => x.psaStatus == 'maintenance').length;
        this.loading = false
      });
    },
    showDetails(row, column) {
      if (column.property) {
        router.push({ name: "PsaDetails", params: { psaId: row.key } });
      }
    },
    filterStatus(value, row) {
      return row.psaStatus === value;
    },
    updateLocation(loc) {
      this.location = loc
      this.loadPsa()
    },
    updatePsaCategory(cat) {
      localStorage.SelectedPsaCategory = this.psaCategory
      this.loadPsa()
    },
    newPsa() {
      this.showNewPsaModal = true;
      const psaTemplateRef = db.collection("psaTemplates");
      psaTemplateRef.orderBy("Name").onSnapshot((snapshotChange) => {
        this.psaTemplates = [];
        snapshotChange.forEach((doc) => {
          this.psaTemplates.push({
            key: doc.id,
            psaTemplateName: doc.data().Name,
            psaType: doc.data().Type,
            psaManufacturer: doc.data().Manufacturer,
            psaItem: doc.data().Item,
            psaGalLink: doc.data().GalLink,
            psaEOL: doc.data().EOL,
          });
        });
      });
    },
    addPsa() {
      console.log(this.newPsaTemplate);
      const psaRef = db.collection("psa");
      psaRef.add({
        Type: this.psaTemplates.find(x => x.psaTemplateName == this.newPsaTemplate).psaType,
        Manufacturer: this.psaTemplates.find(x => x.psaTemplateName == this.newPsaTemplate).psaManufacturer,
        Item: this.psaTemplates.find(x => x.psaTemplateName == this.newPsaTemplate).psaItem,
        GalLink: this.psaTemplates.find(x => x.psaTemplateName == this.newPsaTemplate).psaGalLink,
        SerialNumber: this.newPsaSerialNumer,
        ProdDate: new Date(this.newPsaProdDate),
        FirstUse: new Date(this.newPsaFirstUse),
        LastMaintenance: new Date(this.newPsaFirstUse),
        NextMaintenance: moment(this.newPsaFirstUse).add(1, "years").toDate(),
        EOL: this.psaTemplates.find(x => x.psaTemplateName == this.newPsaTemplate).psaEOL,
        Status: 'OK',
        locationID: this.location,
      }).then(() => {
        this.showNewPsaModal = false;
        this.newPsaTemplate = "";
        this.newPsaSerialNumer = "";
        this.newPsaFirstUse = "";
        this.newPsaProdDate = "";
        this.$notify({
          message: "PSA wurde erfolgreich hinzugefügt!",
          icon: "fa fa-check-circle",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "success",
          showClose: true,
          timeout: 5000,
        });
        this.loadPsa();
      }).catch((error) => {
        this.$notify({
          message: "Fehler beim Hinzufügen der PSA: " + error,
          icon: "fa fa-exclamation-circle",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "danger",
          showClose: true,
          timeout: 5000,
        });
      });
    }
  },
};
</script>
<style>
.el-tag {
  color: white !important;
}
</style>
