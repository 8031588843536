<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">Verleih-Liste</h5>
          </div>
          <!--<div class="col-sm-3 pt-1">
            <SelectLocation v-if="role > 2" @locationChanged="updateLocation"></SelectLocation>
          </div>-->
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="trainer.filter(
                (data) =>
                  !search ||
                  data.trainerName.toLowerCase().includes(search.toLowerCase())
              )"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Einträge gefunden werden."
            v-loading="loading"
            default-expand-all
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="verleihliste">
                  <h6>Verliehene Gegenstände</h6>
                  <table class="">
                    <tr v-for="item in props.row.loanedItems" :key="item">
                      <td class="itemTd"> {{ item }}</td>
                      <td>
                        <el-tooltip content="Entfernen" placement="top">
                          <p-button type="danger" size="sm" icon @click="removeItem(item, props.row.key)">
                            <i class="bi bi-x-circle"></i>
                          </p-button>
                        </el-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td class="itemTd"><el-input placeholder="Hinzufügen..." v-model="props.row.newItem" size="small" @keyup.enter.native="addItem(props.row.newItem, props.row.key)"></el-input></td>
                      <td>
                        <el-tooltip content="Hinzufügen" placement="top">
                          <p-button type="success" size="sm" icon @click="addItem(props.row.newItem, props.row.key)">
                            <i class="bi bi-plus-circle"></i>
                          </p-button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </table>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="trainerName" label="Name">
            </el-table-column>
            <el-table-column prop="loanedItemsCount" label="verliehene Gegenstände">
            </el-table-column>
            <el-table-column
              align="right">
              <template slot="header" slot-scope="scope"> <!-- eslint-disable-line vue/no-unused-vars -->
                <el-input
                  v-model="search"
                  size="mini"
                  placeholder="Trainer suchen..."
                ><i slot="prefix" class="bi bi-search"></i></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Table, TableColumn, Input, Loading, Popover, MessageBox } from "element-ui";
import moment from "moment-timezone";
import router from "../../../../main";
import firebase from 'firebase/compat/app'
import store from "../../../../store";
import SelectLocation from '/src/components/KET/Views/Components/KETSelectLocation.vue'

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Loading);

export default {
  components: {
    [Input.name]: Input,
    SelectLocation,
    MessageBox
  },

  data() {
    return {
      loading: true,
      trainer: [],
      location: store.getters.userlocation,
      role: store.getters.userrole,
      search: "",
    };
  },
  created() {
    this.loadData();
    store.watch(
      (state) => state.userlocation,
      () => {
        this.location = store.getters.userlocation;
        this.loadData();
      }
    );
  },
  methods: {
    loadData() {
      // get list of trainers
      const trainerRef = db.collection("trainer");
      trainerRef.where('locationID', 'array-contains', this.location).orderBy("Name").onSnapshot((snapshotChange) => {
        this.trainer = [];
        snapshotChange.forEach((doc) => {
          this.trainer.push({
            key: doc.id,
            trainerName: doc.data().Name,
            trainerID: doc.data().Id,
            loanedItems: doc.data().loanedItems,
            loanedItemsCount: doc.data().loanedItems.length || 0,
          });
        });
        this.loading = false
      });
    },
    addItem(newItem, trainerId) {
      if (newItem.length > 0) {
        const trainerRef = db.collection("trainer").doc(trainerId);
        trainerRef.update({
          loanedItems: firebase.firestore.FieldValue.arrayUnion(newItem)
        });
      }
    },
    removeItem(item, trainerId) {
      MessageBox.confirm(
        "Soll der Gegenstand " +
          item +
          " wirklich entfernt werden?",
        "Warnung",
        {
          confirmButtonText: "Entfernen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              const trainerRef = db.collection("trainer").doc(trainerId);
              trainerRef.update({
                loanedItems: firebase.firestore.FieldValue.arrayRemove(item)
              });
            }
          },
        }
      );
    },
    updateLocation(loc) {
      this.location = loc
      this.loadData()
    }
  },
};
</script>
<style>
.verleihliste {
  padding-left: 80px;;
}
.verleihliste td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}
.verleihliste .el-input {
  width: 100%;
}
.itemTd {
  width: 400px;
}
</style>
