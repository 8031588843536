<template>
  <div>
    <div class="col-sm-12">
      <h5 class="info-text">Zusammenfassung</h5>
    </div>
    <div class="row">
      <div class="col-sm-7">
        <div class="row">
          <div class="col-sm-7">Kasse</div>
          <div class="col-sm-5">{{ kasse }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Kassentrainer</div>
          <div class="col-sm-5">{{ kassentrainer }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Haupttrainer</div>
          <div class="col-sm-5">{{ HT }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Bankeinzahlung durch</div>
          <div class="col-sm-5">{{ bankeinzahlungTrainer }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Betrag der Bankeinzahlung</div>
          <div class="col-sm-5">{{ bankeinzahlungBetrag }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Wechselgeld nach Kassenabrechnung</div>
          <div class="col-sm-5">{{ wechselgeld }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Kartenzahlungen</div>
          <div class="col-sm-5">{{ ecschnitt }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Online bezahlt</div>
          <div class="col-sm-5">{{ onlinebez }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Gutscheine</div>
          <div class="col-sm-5">{{ gutscheine }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Ausgaben</div>
          <div class="col-sm-5">{{ ausgaben }}</div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7">Gesamtumsatz</div>
          <div class="col-sm-5">
            {{
              zfin
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
            }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-7"><span :class="{ 'redtext': kassendifferenz > 0 || kassendifferenz < 0 }">Kassendifferenz</span></div>
          <div class="col-sm-5"><span :class="{ 'redtext': kassendifferenz > 0 || kassendifferenz < 0 }">
            {{
              (kassendifferenz + 0)
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
            }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <textarea
          class="form-control px-2"
          placeholder="Anmerkung zum Kassenabschluss..."
          rows="7"
          v-model="newAnmerkung"
        ></textarea>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../../firebaseDb";
import {
  Table,
  TableColumn,
  Tooltip,
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
} from "element-ui";
import moment from "moment-timezone";
import router from "../../../../../main";
import { MessageBox } from "element-ui";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import store from "../../../../../store";
import Button from "../../../../UIComponents/Button.vue";

extend("required", required);

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(TimePicker);

export default {
  components: {
    Button,
  },
  data() {
    return {
      date: moment().format("DD.MM.YYYY"),
      newAnmerkung: "",
      kasse: "",
      kassentrainer: "",
      HT: "",
      bankeinzahlungTrainer: "",
      bankeinzahlungBetrag: 0,
      wechselgeld: 0,
      zfin: 0,
      onlinebez: 0,
      gutscheine: 0,
      ausgaben: 0,
      kassendifferenz: 0,
      ecschnitt: 0,
      Cent1: 0,
      Cent2: 0,
      Cent5: 0,
      Cent10: 0,
      Cent20: 0,
      Cent50: 0,
      Euro1: 0,
      Euro2: 0,
      Euro5: 0,
      Euro10: 0,
      Euro20: 0,
      Euro50: 0,
      Euro100: 0,
      Euro200: 0,
      Euro500: 0,
      sumCoins: 0,
      sumNotes: 0,
      sumTotal: 0,
      errors: ''
    };
  },
  created() {},
  mounted() {
    this.$watch("$parent.active", (newVal, oldVal) => {
      if (newVal === true) {
        this.updateValues();
      }
    });
  },
  methods: {
    validate() {
      return true;
    },
    updateValues() {
      this.HT = store.getters.HT;
      this.kasse = store.getters.kasse;
      this.kassentrainer = store.getters.kassentrainer;
      this.bankeinzahlungTrainer = store.getters.banktransferTrainer;
      this.Cent1 = store.getters.cash.data.Cent1;
      this.Cent2 = store.getters.cash.data.Cent2;
      this.Cent5 = store.getters.cash.data.Cent5;
      this.Cent10 = store.getters.cash.data.Cent10;
      this.Cent20 = store.getters.cash.data.Cent20;
      this.Cent50 = store.getters.cash.data.Cent50;
      this.Euro1 = store.getters.cash.data.Euro1;
      this.Euro2 = store.getters.cash.data.Euro2;
      this.Euro5 = store.getters.cash.data.Euro5 - store.getters.banktransfer.data.Euro5Bank;
      this.Euro10 = store.getters.cash.data.Euro10 - store.getters.banktransfer.data.Euro10Bank;
      this.Euro20 = store.getters.cash.data.Euro20 - store.getters.banktransfer.data.Euro20Bank;
      this.Euro50 = store.getters.cash.data.Euro50 - store.getters.banktransfer.data.Euro50Bank;
      this.Euro100 = store.getters.cash.data.Euro100 - store.getters.banktransfer.data.Euro100Bank;
      this.Euro200 = store.getters.cash.data.Euro200 - store.getters.banktransfer.data.Euro200Bank;
      this.Euro500 = store.getters.cash.data.Euro500 - store.getters.banktransfer.data.Euro500Bank;
      this.sumCoins =
        store.getters.cash.data.Cent1 * 0.01 +
        store.getters.cash.data.Cent2 * 0.02 +
        store.getters.cash.data.Cent5 * 0.05 +
        store.getters.cash.data.Cent10 * 0.1 +
        store.getters.cash.data.Cent20 * 0.2 +
        store.getters.cash.data.Cent50 * 0.5 +
        store.getters.cash.data.Euro1 * 1 +
        store.getters.cash.data.Euro2 * 2;
      this.sumNotes =
        (store.getters.cash.data.Euro5 - store.getters.banktransfer.data.Euro5Bank) * 5 +
        (store.getters.cash.data.Euro10 - store.getters.banktransfer.data.Euro10Bank) * 10 +
        (store.getters.cash.data.Euro20 - store.getters.banktransfer.data.Euro20Bank) * 20 +
        (store.getters.cash.data.Euro50 - store.getters.banktransfer.data.Euro50Bank) * 50 +
        (store.getters.cash.data.Euro100 - store.getters.banktransfer.data.Euro100Bank) * 100 +
        (store.getters.cash.data.Euro200 - store.getters.banktransfer.data.Euro200Bank) * 200 +
        (store.getters.cash.data.Euro500 - store.getters.banktransfer.data.Euro500Bank) * 500;
      this.sumTotal = (+this.sumCoins + +this.sumNotes)
        .toFixed(2)
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      this.bankeinzahlungBetrag =
        store.getters.banktransfer.data.SumBank
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €";
      this.wechselgeld =
        (store.getters.cash.data.SumAll - store.getters.banktransfer.data.SumBank)
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €";
      this.ecschnitt = store.getters.ecschnitt.replace(".", ",") + " €" ;
      this.zfin =
        +store.getters.ecschnitt +
        +store.getters.berechnetzbon +
        +store.getters.ausgaben +
        +store.getters.banktransfer.data.SumBank +
        +store.getters.onlinebez -
        store.getters.cashfloat;
      this.kassendifferenz =
        store.getters.cash.data.SumAll -
        store.getters.banktransfer.data.SumBank -
        store.getters.berechnetzbon;
      this.onlinebez = parseFloat(store.getters.onlinebez).toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €";;
      this.gutscheine = parseFloat(store.getters.gutscheine).toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €";;
      this.ausgaben = parseFloat(store.getters.ausgaben).toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €";;
    },
  },
};
</script>
<style lang="scss">
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.el-select {
  display: block;
}
.redtext {
  color: red;
  font-weight: bold;
}
</style>
