<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Checklisten verwalten</h5>
          </div>
          <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6" v-if="role == 3">
            <!--<div>
              <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="loadCheckItems"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
              </el-select>
            </div>-->
            </div>
              <div class="col">
              <div>
              <el-select
                placeholder="Checkliste wählen"
                v-model="activeList"
                @change="loadCheckItems">
                <el-option v-for="list in checklists"
                  :value="list.value"
                  :label="list.name"
                  :key="list.value">
                </el-option>
              </el-select>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          
          <el-table :data="listEntries" header-row-class-name="text-primary" empty-text="Es wurde keine Checkliste ausgewählt.">
            <el-table-column prop="position"
                             label="#"
                             width="100">
              <template slot-scope="scope">
                <el-input size="mini" type="number"
                  v-model="scope.row.position" controls-position="right"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="checkItem"
                             label="Aufgabe"
                             width="800">
              <template slot-scope="scope">
                <el-input size="small"
                  style="text-align:center"
                  v-model="scope.row.checkItem" controls-position="right"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="fieldType"
                             label="Eingabefeld"
                             width="200">
              <template slot-scope="scope">
              <el-select v-model="scope.row.fieldType">
                <el-option value="1" label="Checkbox" key="1" :disabled="activeList == 'Huette'"></el-option>
                <el-option value="2" label="Checkbox + Zahl" key="2" :disabled="activeList == 'Huette'"></el-option>
                <el-option value="3" label="Checkbox + Trainer" key="3" :disabled="activeList == 'Huette'"></el-option>
                <el-option value="4" label="Checkbox + Text" key="4"></el-option>
              </el-select>
              </template>
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label="">
              
              <template slot-scope="props">
                <el-tooltip content="Änderungen speichern" placement="top">
                  <p-button type="success" size="sm" icon @click="editItem(props.$index, props.row)">
                    <i class="fa fa-save"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Item löschen" placement="top">
                  <p-button type="danger" size="sm" icon @click="removeItem(props.$index, props.row)">
                    <i class="fa fa-trash"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div class="col-md-6 pt-4">
      <card>
        <h6 slot="header" class="card-title">
          Aufgabe hinzufügen
        </h6>

        <fg-input placeholder="Text" label="Text" v-model="newItem" :disabled="activeList == null"></fg-input>
        <p-radio label="1" v-model="newType" :inline="true" :disabled="activeList == null || activeList == 'Huette'">nur Checkbox</p-radio>
        <p-radio label="2" v-model="newType" :inline="true" :disabled="activeList == null || activeList == 'Huette'">Chechbox + Zahl</p-radio>
        <p-radio label="3" v-model="newType" :inline="true" :disabled="activeList == null || activeList == 'Huette'">Checkbox + Trainer</p-radio>
        <p-radio label="4" v-model="newType" :inline="true" :disabled="activeList == null">Checkbox + Text</p-radio>

        <p-button type="success" :disabled="activeList == null || newItem == null || newType == null" @click="addItem()">Hinzufügen</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->


        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { db } from '../../../../firebaseDb'
  import {Table, TableColumn, Tooltip, Select, Option, Input} from 'element-ui'
  import store from "../../../../store";
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Tooltip)

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Input.name]: Input
    },

    data () {
      return {
          activeList: null,
          listEntries: [],
          checklists: [ {
            name: 'Kasse Betriebsbeginn',
            value: 'KasseStart'
          },
          {
            name: 'Kasse Betriebsende',
            value: 'KasseEnde'
          },
          {
            name: 'HT Betriebsbeginn',
            value: 'HTStart'
          },
          {
            name: 'HT Betriebsende',
            value: 'HTEnde'
          },
          {
            name: 'Hüttencheckliste',
            value: 'Huette'
          },
          {
            name: 'Veranstaltung Beginn',
            value: 'VAStart'
          },
          {
            name: 'Veranstaltung Ende',
            value: 'VAEnde'
          },
          {
            name: 'Büro wöchentlich',
            value: 'Buero-weekly',
            group: 'Buero'
          },
          {
            name: 'Büro monatlich',
            value: 'Buero-monthly',
            group: 'Buero'
          },
          {
            name: 'Büro Saisonende',
            value: 'Buero-endseason',
            group: 'Buero'
          },
          {
            name: 'Büro Winter',
            value: 'Buero-winter',
            group: 'Buero'
          },
          {
            name: 'Büro Saisonvorbeitung',
            value: 'Buero-preseason',
            group: 'Buero'
          }
          ],
          newItem: null,
          newType: null,
          newPos: 0,
          location: store.getters.userlocation,
          role: store.getters.userrole
      }
    },
    created () {
        this.loadCheckItems(this.activeList)
        store.watch(
          (state) => state.userlocation,
          () => {
            this.location = store.getters.userlocation;
            this.loadCheckItems(this.activeList);
          }
        );
    },
    methods: {
      loadCheckItems () {
        const listItemsRef = db.collection('checklists/' + this.activeList + '/Items')

          listItemsRef.where('locationID', '==', this.location).orderBy('position').onSnapshot((snapshotChange) => {
          this.listEntries = []
          snapshotChange.forEach((doc) => {
                var fieldTypeDesc
                if(doc.data().fieldType == 1) {
                  fieldTypeDesc = 'nur Checkbox'
                }
                if(doc.data().fieldType == 2) {
                  fieldTypeDesc = 'Checkbox + Zahl'
                }
                if(doc.data().fieldType == 3) {
                  fieldTypeDesc = 'Checkbox + Trainer'
                }
                if(doc.data().fieldType == 4) {
                  fieldTypeDesc = 'Checkbox + Text'
                }
                this.listEntries.push({
                  key: doc.id,
                  checkItem: doc.data().Item,
                  fieldType: doc.data().fieldType,
                  fieldTypeDesc: fieldTypeDesc,
                  position: doc.data().position
                })
            })
        })   
      },
      removeItem (index, row) {
        db.collection('checklists/' + this.activeList + '/Items').doc(row.key).delete().then(() => {
          console.log('Item ' + row.checkItem + ' removed.')
        }).catch(() => {
          console.log('Error removing item.')
        })
      },
      addItem () {
        this.newPos = (Math.max.apply(Math, this.listEntries.map(function(obj) { return obj.position; }))) + 1 
        if(this.newPos === -Infinity) {
          this.newPos = 1
        }
        db.collection('checklists/' + this.activeList + '/Items').add({
          Item: this.newItem,
          fieldType: this.newType,
          position: this.newPos,
          locationID: this.location
        }).then(() => {
          this.newItem = null
          this.newType = null
          this.newPos = 0
          console.log('Item added.')
        }).catch(() => {
          console.log('Adding item failed')
        })
      },
      editItem (index, row) {        
        db.collection('checklists/' + this.activeList + '/Items').doc(row.key).update({ Item: row.checkItem, fieldType: row.fieldType, position: parseInt(row.position) }).then(() => {
                  console.log('Item updated.')
                }).catch((error) => {
                  console.log('Error updating item ' + error)
                })
      }
    }
  }

</script>
<style>

</style>
